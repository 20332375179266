

<template>
  <transition name="fade">
  <div class="settings">
   <main-nav />
    <main-titlebar myclass="yellowBar" showback="true"/>

 
    <!-- <Form @submit="saveProfile" > -->
    <Form @submit="saveProfile" :validation-schema="schema">
    <div class="trip-view-wrapper">

      <div v-for="p in profile" :key="p.id" >


      <Field name="id"  v-model="p.id"  type="hidden"/>
      <Field name="address"  v-model="address"  type="hidden"/>


        <!-- Name -->
        <div class="form-group">
            <Field name="fullname" id="fullname" class="form-input" v-model="p.fullname"  type="text"  placeholder="Naam"/>
        </div>
        <div class="form-group">
          <ErrorMessage name="fullname" class="error-feedback" />
        </div>

        <!-- Phone -->
        <div class="form-group">
            <Field name="phone" id="phone" class="form-input" v-model="p.phone"  type="text" placeholder="Telefoon"/>
        </div>
        <div class="form-group">
          <ErrorMessage name="address" class="error-feedback" />
        </div>

        <!-- Email -->
        <div class="form-group">
          <div class="trip-view-col">

            {{p.email}}
            </div>
        </div>

        <div class="form-group">
         <!-- <label for="origin">Pickup / origin</label> -->
        <GMapAutocomplete 
        id="origin"
        name="origin"
        :value="address"
        class="form-input"
        placeholder="Adres"
        @place_changed="setAddress"
        >
        </GMapAutocomplete>
      </div>
      <div class="form-group">
        <ErrorMessage name="origin" class="error-feedback" />
      </div>



        <!-- P nr -->
        <div class="form-group">
          <Field name="pnr" id="pnr" class="form-input" v-model="p.pnr"  type="text" placeholder="P nummer"/>
        </div>
        <div class="form-group">
          <ErrorMessage name="pnr" class="error-feedback" />
        </div>

        <!-- KvK -->
        <div class="form-group">
          <Field name="kvk" id="kvk" class="form-input" v-model="p.kvk"  type="text" placeholder="KvK nummer"/>
        </div>

        <!-- BTW -->
        <div class="form-group">
          <Field name="btw" id="btw" class="form-input" v-model="p.btw"  type="text" placeholder="BTW nummer"/>
        </div>
        <!-- BSN -->
        <div class="form-group">
          <Field name="bsn" id="bsn" class="form-input" v-model="p.bsn"  type="text" placeholder="BSN nummer"/>
        </div>        

        <!-- Driver card -->
        <div class="form-group">
          <Field name="dcard" id="dcard" class="form-input" v-model="p.dcard"  type="text" placeholder="Chauffeurskaart"/>
        </div>


        <div class="trip-view-row">
          <div class="trip-view-col">
            <button class="default-button ">
              <span
                
                class="spinner-border spinner-border-sm"
              ></span>
              <span><i class="fa-solid fa-save"></i> Opslaan</span>
            </button>
          </div>
        </div>

         <div class="form-group">
            <div @click="testMail">---</div>
        </div>


        </div>

    </div>

    </Form>


  </div>
  </transition>
</template>
<script>
import UserService from "../../services/user-service";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name:'edit-profile',
  data() {
    var status = 'loading'
    var profile;
    var address;
    const schema = yup.object().shape({
      fullname: yup.string().required("Naam is verplicht")
    });

    return { profile, status, schema, address}
  },
  mounted() {
    var targ = this;
    UserService.getMyProfile().then(
      (response) => {
        targ.status = response.status;
        targ.profile = response.data;
        targ.address = response.data[0]["address"];
      },
      (error) => {
          error.toString();
      }
    );
  },
  methods: {
    setAddress(targ) {
      this.setPlace(targ, "address")
    },
    setPlace(targ) {
      console.log("setPlace: details:" + JSON.stringify(targ));
      this.address = targ.formatted_address
    },
    saveProfile(user) {
     this.loading = true;
      console.log("saveProfile>> user:" + JSON.stringify(user));

      UserService.saveMyProfile(user).then(
        (response) => {
           console.log("saveProfile>> response:" + JSON.stringify(response));
           if(response.status == "failed") {
            alert("saveContact>> Error: "+ response.message);
            // this.$router.go();
           } else {
             this.content = response.data;
          this.$router.push("/my/profile");
           }
        },
        (error) => {
          console.log("saveContact>> error:" + JSON.stringify(error));
          this.content = error.toString();
   
        }
      );
    },
    testMail() {
      console.log("testMail>>");

      UserService.testMail().then(
        (response) => {
           console.log("testMail>> response:" + JSON.stringify(response));
           if(response.status == "failed") {
            alert("testMail>> Error: "+ response.message);
            // this.$router.go();
           } else {
             this.content = response.data;
            // this.$router.push("/my/profile");
           }
        },
        (error) => {
          console.log("testMail>> error:" + JSON.stringify(error));
          this.content = error.toString();
 
        }
      );
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,
  }
}
</script>
